*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
}

.scroll-ofset-padding {
  /* header height */
  scroll-margin-top: 80px;
}
