@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HurmeGeometricSans4';
  src: url('./fonts/HurmeGeometricSans4.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
